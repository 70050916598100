<script setup>
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  showLink: {
    type: Boolean,
    required: false,
    default: true,
  },
})

const { t } = useI18n()

const colors = computed(() => {
  if (!props.product?.variants)
    return []

  return props.product.variants.map(x => x.color).reduce((acc, curr) => {
    if (!acc.find(x => x?.title === curr?.title) && curr?.code)
      acc.push(curr)
    return acc
  }, [])
})
</script>

<template>
  <div>
    <NuxtLinkLocale
      class="hover:(shadow-input-big scale-102) group flex w-full flex-none -translate-y-1 transform cursor-pointer flex-col rounded-lg border bg-white p-2 transition duration-300"
      :to="`/product/${product.id}`"
    >
      <div>
        <img
          v-if="product?.images[0]"
          class="aspect-square w-full rounded object-contain"
          :src="useImageProxy(product?.images[0].url, 960, 0)"
          :alt="product?.images[0].title"
          loading="lazy"
        >
        <img
          v-else
          class="aspect-square w-full rounded bg-whiteobject-contain p-8"
          loading="lazy"
          src="~/assets/images/giardino-logo.svg"
          alt="Giardino logo"
        >
      </div>

      <div class="p-2 gap-4 flex flex-col">
        <div class="flex flex-col gap-2">
          <div class="min-h-12 md:min-h-14 line-clamp-2 text-base md:text-xl font-bold">
            {{ product.title }}
          </div>
        </div>

        <div v-if="colors.length > 0" class="flex flex-row gap-1">
          <div
            v-for="color in colors"
            :key="color"
            class="border-primary border h-6 w-6 rounded-full"
            :style="`background-color: ${color?.code};`"
          />
        </div>
        <div v-else-if="product?.variants" class="h-6" />

        <div v-if="showLink" class="flex flex-row items-center gap-4">
          <div class="text-sm md:text-base">
            {{ t('product.view_product') }}
          </div>
          <img
            class="group-hover:(animate-animated animate-infinite animate-shake-x !animate-duration-5000 animate-delay-300) mt-[2px]"
            src="~/assets/images/icons/chevron-right.svg"
            alt="Chevron"
          >
        </div>
      </div>
    </NuxtLinkLocale>
  </div>
</template>
